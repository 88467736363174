<template>
  <b-card class="shadow-sm">
    <form @submit.prevent="handleSubmit" id="login-form" class="mb-2">
      <b-form-group>
        <label class="text-muted" for="email"> <small>Email</small></label>
        <b-form-input
          type="email"
          v-model="credentialsCopy.email"
          id="email"
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <label class="text-muted" for="password"><small>Password</small></label>
        <b-form-input
          v-model="credentialsCopy.password"
          type="password"
          id="password"
          class="mb-1"
        ></b-form-input>
        <router-link :to="{ name: 'AuthPassForgot' }"
          ><small>Forgot Password</small></router-link
        >
      </b-form-group>
      <b-row v-if="error">
        <b-col>
          <p class="mb-0 text-danger">
            {{ error }}
          </p>
        </b-col>
      </b-row>
      <hr class="pt-0 pb-0" />
      <b-button type="submit" variant="primary" block>Login</b-button>
    </form>
    <template #footer>
      <div class="text-center w-100">
        <small>
          <router-link :to="{ name: 'AuthRegister' }">
            Don't have an account yet? Create Account
          </router-link>
        </small>
      </div>
    </template>
  </b-card>
</template>

<script>
  export default {
    name: 'LoginForm',

    props: {
      credentials: {
        type: Object,
        required: true
      },
      error: {
        type: String
      }
    },

    data () {
      return {
        credentialsCopy: { ...this.credentials }
      }
    },

    methods: {
      handleSubmit () {
        this.$emit('submitting', this.credentialsCopy)
      }
    }
  }
</script>

<style>
</style>
