<template>
  <b-container>
    <b-row
      style="height: 100vh"
      class="justify-content-center align-items-center"
    >
      <b-col lg="4">
        <div class="w-100 text-center mb-4">
          <h4 class="mb-0 font-weight-bold text-primary">Please Signin</h4>
        </div>
        <login-form
          :credentials="credentials"
          :error="error"
          @submitting="login"
        ></login-form>
      </b-col>
    </b-row>
    <app-spinner ref="spinner"></app-spinner>
  </b-container>
</template>

<script>
  import UserService from '@/services/UserService'
  import LoginForm from '@/components/LoginForm'

  export default {
    name: 'AuthLogin',

    components: {
      LoginForm
    },

    data () {
      return {
        credentials: {},
        error: ''
      }
    },

    methods: {
      async login (loginData) {
        this.$refs.spinner.show()

        try {
          const { data } = await UserService.login(loginData)
          this.$store.commit('SET_USER_DATA', data)
          this.$router.push({ name: 'AuthOtp' })
        } catch (error) {
          console.log(error.response.data)
          this.error = error.response.data.message
        }

        this.$refs.spinner.hide()
      }
    }
  }
</script>

<style></style>
